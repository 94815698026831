<template>
  <div>
    <v-row no-gutters>
      <SubBar :title="subBarTitle" route_name="Home" />
    </v-row>
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <!-- <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 40%">
            <v-col cols="6">
              <dashboardCard
                module_name="saisir_des_tarifs"
                :title_card="titleTarifDashboard"
                auth
              />
            </v-col>
            <v-col cols="6">
              <dashboardCard
                module_name="visualiser_des_templates"
                :title_card="titleTemplateDashboard"
                auth
              />
            </v-col>
          </v-row>
        </v-col> -->
        <!-- <span>Module désactivé en attente de correctifs</span> -->
      </v-row>
    </v-col>
  </div>
</template>
<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
// import dashboardCard from "@/components/Common/DashboardCard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SubBar,
    // dashboardCard,
  },
  computed: {
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),

    titleTarifDashboard() {
      const saison = this.currentSaison.id;
      const nextSaison = saison + 1;
      return "Données - Saison " + nextSaison;
    },

    titleTemplateDashboard() {
      const saison = this.currentSaison.id;
      const nextSaison = saison + 1;
      return "Liste des templates - Saison " + nextSaison;
    },

    saisons() {
      let saisons = [];
      for (
        let saison = this.currentSaison.id;
        saison >= this.currentSaison.id - 1;
        saison--
      ) {
        saisons = [
          ...saisons,
          { name: `Saison ${saison + 1}`, value: saison + 1 },
        ];
      }
      return saisons;
    },
  },
  data() {
    return {
      subBarTitle: "Gestion des parametres globaux",
    };
  },
};
</script>
<style scoped>
span {
  margin: 30vh 0 0 200px;
  font-size: 20px;
  color: red;
}
</style>
