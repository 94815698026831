<template>
  <div>
    <subBar
      :title="titleTemplateDashboard"
      route_name="gestion_des_parametres_globaux"
    ></subBar>

    <v-col cols="12 py-6">
      <v-row v-for="(item, index) in allDocumentType" :key="index">
        <v-col
          cols="12"
          md="9"
          class="py-0"
          style="display: flex; align-items: center"
        >
          <div class="px-2 py-2">
            <span class="font-weight-bold" style="font-size: 0.9rem">
              {{ item.NomTypeDocument }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="text-right">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="ml-2"
                fab
                dark
                x-small
                outlined
                color="indigo darken-4"
                @click="openPreview(item.NomTypeDocument)"
              >
                <v-icon>mdi-image</v-icon>
                <modalPreviewDocument
                  @onClose="modalPreviewIsOpen = false"
                  :isOpen="modalPreviewIsOpen"
                  v-if="modalPreviewIsOpen"
                  :context="{
                    document: NoticeTemplate,
                    documentType: documentType,
                  }"
                ></modalPreviewDocument>
              </v-btn>
            </template>
            <span>Afficher le document</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                outlined
                x-small
                color="indigo darken-4"
                @click="openModalUpload(item)"
              >
                <v-icon dark>mdi-plus</v-icon>
                <modalUploadDocument
                  @onClose="modalUploadDocumentIsOpen = false"
                  :isOpen="modalUploadDocumentIsOpen"
                  :dateneeded="false"
                  v-if="modalUploadDocumentIsOpen"
                  :context="{
                    documentType: documentType,
                  }"
                  @onFileUploaded="fileUploaded"
                ></modalUploadDocument>
              </v-btn>
            </template>
            <span>Téléverser un nouveau document</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import subBar from "@/components/Common/Common__SubBar.vue";
import modalPreviewDocument from "@/components/utils/modals/Documents/Document__PreviewDocument.vue";
import modalUploadDocument from "@/components/utils/modals/Documents/Document__UploadDocument.vue";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    subBar,
    modalPreviewDocument,
    modalUploadDocument,
  },
  data: () => ({
    allDocumentType: [
      { NomTypeDocument: "Notice d'assurance" },
      { NomTypeDocument: "Visuel licence" },
      { NomTypeDocument: "Visuel attestation RC" },
      { NomTypeDocument: "Visuel attestation affiliation" },
    ],
    modalHistoryIsOpen: false,
    modalUploadDocumentIsOpen: false,
    modalPreviewIsOpen: false,
    mounted: false,
    documentType: null,
    SlugNomTemplate: null,
    NomTemplate: null,
    template: null,
    hasTemplate: false,
  }),

  async created() {
    this.getTemplate();
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    saison() {
      let saison;
      saison = this.currentSaison.id + 1;
      return saison;
    },
    titleTemplateDashboard() {
      const saison = this.currentSaison.id;
      const nextSaison = saison + 1;
      return "Liste des templates de la saison " + nextSaison;
    },
    NoticeTemplate() {
      // if (!this.template) return null;
      let temp;
      temp = this.template?.filter((t) => t.NomTemplate === this.documentType);
      if (temp) {
        return temp[0];
      }
      return null;
    },
  },

  methods: {
    ...mapActions("basics", [
      "insertTemplate",
      "getTemplates",
      "deleteTemplate",
    ]),
    openPreview(item) {
      this.documentType = item;
      // if (!this.hasDocument) return false;
      return (this.modalPreviewIsOpen = true);
    },

    openModalUpload(item) {
      this.documentType = item;
      this.SlugNomTemplate = item?.NomTypeDocument.replace(
        /['"]/g,
        "_"
      ).replace(/ /g, "_");
      this.NomTemplate = item?.NomTypeDocument;
      this.modalUploadDocumentIsOpen = true;
    },
    async getTemplate() {
      let payload = {
        ID_Saison: this.currentSaison.id + 1,
      };
      this.template = await this.getTemplates(payload);
      return this.template;
    },
    async fileUploaded(file) {
      this.modalUploadDocumentIsOpen = false;
      let document = await this.insertFileInDB(file);
    },

    async deleteTemplateExist() {
      let payload = {
        ID_Saison: this.saison,
        NomTemplate: this.NomTemplate,
      };
      try {
        let response = await this.deleteTemplate(payload);
        // success_notification("Template existant supprimé");
        return response;
      } catch (error) {
        console.log("erreur suppression", error);
      }
    },

    async insertFileInDB(file) {
      let payloadInsert = {
        CheminDocument: file.url,
        NomDocument: file.filename,
        SlugNomTemplate: this.SlugNomTemplate,
        NomTemplate: this.NomTemplate,
        ID_Saison: this.saison,
      };
      try {
        this.deleteTemplateExist();
        let document = await this.insertTemplate(payloadInsert);
        this.template.push(document);
        this.getTemplate();
        success_notification("Le fichier est enregistré");
        return document;
      } catch (e) {
        console.log(e);
        error_notification(
          "Oups, une erreur est survenue lors du téléchargement du document..."
        );
      }
    },
  },
};
</script>