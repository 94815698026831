<template>
  <div>
    <subBar :title="title" route_name="gestion_des_parametres_globaux"></subBar>
    <v-row justify="center">
      <v-col cols="12">
        <div class="formStyle">
          <ValidateForm
            @validated="saveTarifs"
            :loading="loading"
            :showFieldObl="false"
          >
            <v-col>
              <div class="row">
                <div style="width: 20%"></div>
                <div
                  class="col scrollV"
                  style="height: 90vh; padding: 0; overflow-x: hidden"
                >
                  <div class="content" style="height: 95%; overflow-x: hidden">
                    <div
                      class="row"
                      v-for="(item, index) in tarifs"
                      :key="index"
                    >
                      <div class="col" style="margin-top: 16px">
                        {{ item.NomTarif }}
                      </div>
                      <InputText
                        v-if="item.NomTarif !== 'Numéro de contrat assurance'"
                        class="col"
                        v-model="tarifs[index].MontantUnitaire"
                        type="number"
                      ></InputText>
                      <InputText
                        v-else
                        class="col"
                        v-model="tarifs[index].NumeroContratAssurance"
                        type="text"
                      ></InputText>
                    </div>
                    <v-btn
                      style="margin-top: 50px"
                      type="submit"
                      :loading="loading"
                      class="btn btn-primary"
                      >Enregistrer</v-btn
                    >
                  </div>
                </div>
              </div>
            </v-col>
          </ValidateForm>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import subBar from "@/components/Common/Common__SubBar.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
// import InputSelect from "@/components/utils/select.vue";
import InputText from "@/components/utils/input.vue";
import ValidateForm from "@/components/utils/form.vue";

import { mapActions, mapGetters } from "vuex";
const TARIFS = {
  "Licence adulte": 0,
  "Licence jeune": 0,
  "Licence famille": 0,
  "Hors club adulte": 0,
  "Hors club jeune": 0,
  "Licence découverte": 0,
  "Assurance RC": 0,
  "Assurance BASE": 0,
  "Assurance BASE +": 0,
  "Assurance BASE ++": 0,
  "Option assurance ski de piste": 0,
  "Option assurance slackline": 0,
  "Option assurance trail": 0,
  "Option assurance VTT": 0,
  "Assurance IJ1": 0,
  "Assurance IJ2": 0,
  "Assurance IJ3": 0,
  "Option voyage à l'étranger": 0,
  "Affiliation club": 0,
  "Affiliation EA MA": 0,
  "Coût abonnement outil mailing": 0,
  "Numéro de contrat assurance": "0",
};

// const TARIFS_BDD = {
//   "Licence adulte": 2,
// };
export default {
  components: {
    subBar,
    ValidateForm,
    InputText,
  },
  data: () => ({
    mapTarifs: {},
    mounted: false,
    loading: false,
    saisonSelected: null,
    NomTarif: null,
    MontantUnitaire: null,
    headers: [
      {
        text: "Nom du tarif",
        value: "name",
      },
      {
        text: "Montant",
        value: "montant",
      },
    ],
    TARIFS_BDD: [],
    tarifs: [],
    NumeroContratAssurance: null,
  }),

  async created() {
    // await this.setData();
    this.mounted = true;
    this.saisonSelected = this.currentSaison.id;
    this.getTarifs();
  },

  computed: {
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),
    ...mapGetters("structure", ["currentStructure"]),

    title() {
      const saison = this.currentSaison.id;
      const nextSaison = saison + 1;
      return "Données de la saison " + nextSaison;
    },

    saisons() {
      let saisons = [];
      for (
        let saison = this.currentSaison.id;
        saison >= this.currentSaison.id - 1;
        saison--
      ) {
        saisons = [
          ...saisons,
          { name: `Saison ${saison + 1}`, value: saison + 1 },
        ];
      }
      return saisons;
    },
  },
  methods: {
    ...mapActions("basics", ["insertTarifs", "deleteTarifs", "updateTarifs"]),
    ...mapActions("tarifs", ["getTarifsBySaison"]),

    preparePayload() {
      let payload = [];
      // map the model tarif
      let item = {
        ID_Saison: this.saisonSelected,
        MontantUnitaire: this.MontantUnitaire,
        NomTarif: this.NomTarif,
        EST_Actif: false,
      };
      payload.push(item);

      return payload;
    },

    async saveTarifs() {
      const saison = this.currentSaison.id;
      const nextSaison = saison + 1;
      try {
        console.log("this.tarifs", this.tarifs);
        const updates = [];
        const creates = [];
        let nomTarifs = [];
        for (let i = 0; i < this.tarifs.length; i++) {
          let el = this.tarifs[i];
          if (el.action == "update") {
            // delete el.action;
            updates.push(el);
            delete updates[updates.length - 1].action;
          } else {
            // delete el.action;
            creates.push(el);
            delete creates[creates.length - 1].action;
          }
        }
        this.loading = true;
        for (let i = 0; i < updates.length; i++) {
          const el = updates[i];
          nomTarifs.push(el.NomTarif);
        }
        await this.deleteTarifs({ tarifs: nomTarifs, ID_Saison: nextSaison });
        let response = await this.insertTarifs({ tarifs: this.tarifs });
        success_notification("Les tarifs ont bien été enregistrés");
      } catch (e) {
        console.log(e);
        error_notification();
      }
      this.loading = false;
    },
    addItem(montant, name) {
      this.tarifs.push({
        montant,
        name,
      });
    },

    async getTarifs() {
      let saison = this.saisonSelected;
      let payload = {
        ID_Saison: saison + 1,
      };
      let response = await this.getTarifsBySaison(payload);
      this.TARIFS_BDD = response;
      this.intiTarifs(response);
      return response;
    },

    intiTarifs(tarifBdd) {
      this.tarifs = [];
      let saison = this.saisonSelected;
      for (let i = 0; i < tarifBdd.length; i++) {
        const el = tarifBdd[i];

        for (let key in TARIFS) {
          if (key == el.NomTarif && key === "Numéro de contrat assurance") {
            this.mapTarifs[el.NomTarif] = {
              montant: el.NumeroContratAssurance,
              action: "update",
            };
          } else if (key == el.NomTarif) {
            this.mapTarifs[el.NomTarif] = {
              montant: el.MontantUnitaire,
              action: "update",
            };
          }
        }
      }
      for (let key in TARIFS) {
        if (!this.mapTarifs[key]) {
          this.mapTarifs[key] = {
            montant: TARIFS[key],
            action: "create",
          };
        }
      }
      for (let key in this.mapTarifs) {
        if (key === "Numéro de contrat assurance") {
          this.tarifs.push({
            NomTarif: key,
            MontantUnitaire: 0,
            ID_Saison: saison + 1,
            EST_Actif: true,
            NumeroContratAssurance: this.mapTarifs[key].montant,
            action: this.mapTarifs[key].action,
          });
        } else {
          this.tarifs.push({
            NomTarif: key,
            MontantUnitaire: this.mapTarifs[key].montant,
            ID_Saison: saison + 1,
            EST_Actif: true,
            action: this.mapTarifs[key].action,
          });
        }
      }
    },
  },
};
</script>

<style>
@media screen and (max-height: 1000px) {
  .scrollV {
    max-height: 88vh;
  }
}
@media screen and (max-height: 905px) {
  .scrollV {
    max-height: 85vh;
  }
}
@media screen and (max-height: 821px) {
  .scrollV {
    max-height: 80vh;
  }
}
@media screen and (max-height: 700px) {
  .scrollV {
    max-height: 78vh;
  }
}
.activities_selected .v-input__slot {
  overflow: hidden;
  width: 10;
}

.formStyle {
  height: 100%;
}
.input {
  margin-top: -5px;
}
</style>