var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('subBar',{attrs:{"title":_vm.titleTemplateDashboard,"route_name":"gestion_des_parametres_globaux"}}),_c('v-col',{attrs:{"cols":"12 py-6"}},_vm._l((_vm.allDocumentType),function(item,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"py-0",staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"px-2 py-2"},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(item.NomTypeDocument)+" ")])])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":"indigo darken-4"},on:{"click":function($event){return _vm.openPreview(item.NomTypeDocument)}}},on),[_c('v-icon',[_vm._v("mdi-image")]),(_vm.modalPreviewIsOpen)?_c('modalPreviewDocument',{attrs:{"isOpen":_vm.modalPreviewIsOpen,"context":{
                  document: _vm.NoticeTemplate,
                  documentType: _vm.documentType,
                }},on:{"onClose":function($event){_vm.modalPreviewIsOpen = false}}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Afficher le document")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","outlined":"","x-small":"","color":"indigo darken-4"},on:{"click":function($event){return _vm.openModalUpload(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),(_vm.modalUploadDocumentIsOpen)?_c('modalUploadDocument',{attrs:{"isOpen":_vm.modalUploadDocumentIsOpen,"dateneeded":false,"context":{
                  documentType: _vm.documentType,
                }},on:{"onClose":function($event){_vm.modalUploadDocumentIsOpen = false},"onFileUploaded":_vm.fileUploaded}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Téléverser un nouveau document")])])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }